import React, { Component, Suspense } from 'react';
import logo from './logo-new.png';
import './App.css'
import Navbar from './components/common/navbar';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Routes, BrowserRouter } from 'react-router-dom';
import ScrollToTop from './components/common/ScrollToTop'

import store from './store';
const Landing = React.lazy(() => import('./components/layouts/Landing'));
const DataFeeds = React.lazy(() => import('./components/layouts/Data Feeds/DataFeeds'));
const DataFeedsV2 = React.lazy(() => import('./components/layouts/Data Feedsv2/DataFeeds'));

const DataSources = React.lazy(() => import('./components/layouts/Data Sources/DataSources'));
const CategoryFeed = React.lazy(() => import('./components/layouts/Data Feeds/Feeds Categories/CategoryFeed.js'));
const CategoryFeedv2 = React.lazy(() => import('./components/layouts/Data Feedsv2/FeedsCategories/CategoryFeed.js'));

const ComingSoon = React.lazy(() => import('./components/layouts/ComingSoon'));
const NFT = React.lazy(() => import('./components/layouts/NFT/NFT'));
const ViewFeed = React.lazy(() => import('./components/layouts/Data Feeds/View Feeds/ViewFeed'));
const ViewFeedDeposit = React.lazy(() => import('./components/layouts/Data Feeds/View Feeds/ViewFeedDeposit'));

const ViewFeedV2 = React.lazy(() => import('./components/layouts/Data Feedsv2/View Feeds/ViewFeed'));

const Vrf = React.lazy(() => import('./components/layouts/Vrf'));


function App() {
  return (
    <Provider store={store}>
      <BrowserRouter
        exact
        path="/"
        onUpdate={() => window.scrollTo(0, 0)}
      >
        <Navbar />


        {/* ADMIN ROUTE */}
        <ScrollToTop />

        <Suspense fallback={<div className="App">
          <div className="fallback-container">
            <header className="fallback-header">
              <img src={logo} className="fallback-logo" alt="logo" />
              <div className="fallback-content">
                <div className="fallback-spinner"></div>
                <p className="fallback-text">Loading...</p>
              </div>
            </header>
          </div>
        </div>}>


          <Routes>
            <Route index exact path="/" element={<Landing />} />
            {/* <Route index exact path="/datafeed" element={<DataFeeds />} /> */}
            <Route index exact path="/datasources" element={<DataSources />} />
            {/* <Route index exact path="/feedcategory" element={<CategoryFeed />} /> */}
            <Route index exact path="/comingsoon" element={<ComingSoon />} />
            <Route index exact path="/nft" element={<NFT />} />
            {/* <Route index exact path="/viewfeed/:id" element={<ViewFeed />} /> */}
            {/* <Route index exact path="/viewfeeddeposit/:id" element={<ViewFeedDeposit />} /> */}

            <Route index exact path="/datafeedv2" element={<DataFeedsV2 />} />
            <Route index exact path="/viewfeedv2/:id" element={<ViewFeedV2 />} />
            <Route index exact path="/feedcategoryv2" element={<CategoryFeedv2 />} />

            <Route index exact path="/vrf" element={<Vrf />} />







          </Routes>
        </Suspense>
      </BrowserRouter>
    </Provider>



  );
}

export default App;
