import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { API_URL, EXPLORER_LINK } from '../../actions/constant';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import removeScript from '../../utils/removeScript';
import appendScript from '../../utils/appendScript';
import { useDispatch } from 'react-redux';
import { addCategory } from '../../reducers/categoryReducer';

export default function Navbar() {
  const [categoryData, setCategoryData] = useState([]);
  const [categoryLoading, setCategoryLoading] = useState(false)
  const dispatch = useDispatch()



  const navlink = [
    { pageName: "Data Feed", link: "/datafeedv2?categoryID=" },
    { pageName: "VRF", link: "https://vrf.goplugin.co", external: true },
    { pageName: "Fund VRF", link: "/vrf" },
    { pageName: "Build Feed", link: "/comingsoon" },
    { pageName: "NFT Feed", link: "/nft" },
    { pageName: "Data Sources", link: "/datasources" },
    // { pageName: "Contact", link: "/contact" },
  ]

  var location = useLocation()
  const pathname = location.pathname;
  const locs = useLocation();
  const [isToggle, setIsToggle] = useState(false);
  const [submenuToggle, setSubMenuToggle] = useState(false);

  const toggleMenu = () => {
    setIsToggle((prevState) => !prevState);
  };
  const handleSubMenuToggle = () => {
    setSubMenuToggle(prevState => !prevState);
  };
  const offMenu = () => {
    setIsToggle(false);
  };
  console.log("PATHNAME", pathname)
  useEffect(() => {

    getCategoryData()
  }, [])


  const getCategoryData = async () => {

    setCategoryLoading(true)
    try {
      let result = await axios.get(API_URL + `/api/jobcategory/getcategory`)
      setCategoryData(result.data)
      setCategoryLoading(false)
      dispatch(addCategory(result.data))
    } catch (err) {
      Notify.failure('Error Occured');

    }

  }

  var categoryMenu;
  if (!categoryLoading) {
    categoryMenu = categoryData?.map((result, index) => {
      return (
        <li onClick={offMenu}><Link to={`/feedcategoryv2?categoryID=${result._id}&name=${result.categoryName}&description=${result.description}&number_of_jobs=${result.number_of_jobs}`} className="sub-menu-item" > {result.categoryName}</Link></li>

      )
    })
  }
  const navRender = navlink.map(result => {
    let isActive = "";

    if (pathname === result.link) {
      isActive = "active"
    }
    if (result.link === "/datafeedv2?categoryID=") {
      return <li className={`has-submenu  parent-parent-menu-item   ${isActive}`}>
        
        <Link to={`/datafeedv2?categoryID=`} title={result.pageName}
      > Data Feeds </Link> <span className="menu-arrow" onClick={handleSubMenuToggle}></span>
        <ul className={`submenu ${isToggle ? 'open' : ''}`}>

          <li onClick={offMenu} ><Link to={`/datafeedv2?CategoryID=`} className="sub-menu-item"> All Feeds</Link></li>

          <li className="has-submenu parent-menu-item"><a href="javascript:void(0)"> Categories </a><span className="submenu-arrow" />
            <ul className="submenu">
              {categoryMenu}

            </ul>
          </li>
          {/* <li><Link to={`/datafeed?CategoryID=`} className="sub-menu-item"> Plugin 1.0 (Archive)</Link></li> */}

        </ul >
      </li >
    }
    return <li onClick={offMenu} className={`sub-menu-item ${pathname === result.link ? "active" : null} ${isActive} `}>

      {result.external ? <a href={result.link}
        // className="nk-menu-link"
        // data-bs-original-title=""
        target="_blank"
        title={result.pageName}
      >
        {result.pageName}
      </a> : <Link to={result.link}
        // className="nk-menu-link"
        // data-bs-original-title=""
        title={result.pageName}
      >
        {result.pageName}
      </Link>}
    </li>
  })
  return (
    <header id="topnav" className="defaultscroll sticky">
      <div id="token-disp" className="top-header-area">
      Revolutionize gaming with Plugin VRF –  <Link to="https://vrf.goplugin.co/" target='_blank' className=''>Explore It Now  </Link>&nbsp;&nbsp;

      </div>
      <div className="container" style={{ marginLeft: 15 }}>


        {/* Logo container*/}
        <Link className="logo" to="/">
          <span className="logo-light-mode pt-1">
            <img
              src="assets/images/logo-FEEDS-color.png"
              className="l-dark"
              height={70}
              alt=""
            />
            <img
              src="assets/images/logo-FEEDS-white.png"
              className="l-light"
              height={70}
              alt=""
            />
          </span>
          <img
            src="assets/images/logo-FEEDS.color.png"
            height={70}
            className="logo-dark-mode"
            alt=""
          />
        </Link>
        {/* End Logo container*/}
        <div className={`menu-extras ${isToggle ? 'open' : ''}`}>
          <div className="menu-item">
            {/* Mobile menu toggle*/}
            <a className={`navbar-toggle pb-5 ${isToggle ? 'open' : ''}`} onClick={toggleMenu}>
              <div className="lines">
                <span />
                <span />
                <span />
              </div>
            </a>
            {/* End mobile menu toggle*/}
          </div>
        </div>
        {/*Login button Start*/}
        <ul className="buy-button list-inline mb-0">
          <li className="list-inline-item mb-0">
          
          </li>
          <li className="list-inline-item ps-1 mb-0">
            <Link to="/" >
              <div className="btn btn-icon btn-pills btn-primary">
                <i className="fa fa-home" />
              </div>
            </Link>
          </li>
        </ul>
        {/*Login button End*/}
        <div className={`menu ${isToggle ? 'open' : ''}`} id="navigation" style={{ display: isToggle ? 'block' : 'none' }}>
          {/* Navigation Menu*/}
          <ul className="navigation-menu  nav-right">
            {navRender}
        
          </ul>
          {/*end navigation menu*/}
        </div>
        {/*end navigation*/}
      </div>
      {/*end container*/}
    </header>


  )
}
