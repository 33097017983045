import { createSlice } from '@reduxjs/toolkit'





export const counterSlice = createSlice({
    name: 'category',
    initialState: {
        category: [],
    },
    reducers: {
        addCategory: (state, action) => {
            state.category = action.payload
        },
    },
})

export const { addCategory } = counterSlice.actions




export default counterSlice.reducer
